import React from 'react'
import Helmet from 'react-helmet'

import useSiteMetadata from 'hooks/use-site-metadata'
import favicon from './../../static/favicon.ico'

const SEO = () => {
  const { title, description } = useSiteMetadata()
  return (
    <Helmet>
      <html lang="en"></html>
      <link rel="icon" href={favicon} />
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
  )
}

export default SEO
