import React from 'react'
import { preToCodeBlock } from 'mdx-utils'
import Highlight, { defaultProps } from 'prism-react-renderer'

import theme from './theme'
import './code.scss'

const Code = props => {
  const codeProps = preToCodeBlock(props)
  if (!codeProps) {
    return <pre {...props} />
  }
  const { codeString, language } = codeProps

  return (
    <div className="code-container">
      <Highlight
        {...defaultProps}
        code={codeString}
        language={language}
        theme={theme}
      >
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <pre className={`${className} code-snippet`} style={style}>
            {tokens.map((line, i) => (
              <div {...getLineProps({ line, key: i })}>
                {line.map((token, key) => (
                  <span {...getTokenProps({ token, key })}></span>
                ))}
              </div>
            ))}
          </pre>
        )}
      </Highlight>
    </div>
  )
}

export default Code
