import React from 'react'
import { Link } from 'gatsby'

import './header.scss'

const Header = () => (
  <header>
    <Link to="/">
      <div className="logo-link">
        <div className="name">Marek Slawinski</div>
        <div className="line-of-work">Full Stack Developer</div>
      </div>
    </Link>
    <nav>
      <Link to="/" activeClassName="active">
        <span className="nav-link">Home</span>
      </Link>
    </nav>
  </header>
)

export default Header
