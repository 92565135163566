import React  from 'react'
import { MDXProvider } from '@mdx-js/react'

import Code from 'components/code'
import Header from 'components/header'
import SEO from 'utils/seo'
import './layout.scss'

const Layout = ({ children }) => (
  <>
    <SEO />
    <span id="top"></span>
    <div className="page-container">
      <Header />
      <MDXProvider components={{ pre: Code }}>
        <main>{children}</main>
      </MDXProvider>
    </div>
  </>
)

export default Layout
