const Color = {
  Red: '#cc241d',
  Green: '#98971a',
  Yellow: '#d79921',
  Blue: '#076678',
  Purple: '#b16286',
  Aqua: '#689d6a',
  Orange: '#d65d0e',
  Pink: '#f3a6b4',
  Gray: '#3c3836',
  GrayLight: '#928374',
  Background: '#faf8f5',
}

const theme = {
  plain: {
    backgroundColor: Color.Background,
    color: Color.Gray,
  },
  styles: [
    {
      types: ['keyword', 'variable'],
      style: {
        color: Color.Red,
      },
    },
    {
      types: ['property'],
      style: {
        color: Color.Yellow,
      },
    },
    {
      types: ['attr-name'],
      style: {
        color: Color.Aqua,
      },
    },
    {
      types: ['attr-value', 'number', 'boolean', 'unit'],
      style: {
        color: Color.Purple,
      },
    },
    {
      types: ['string'],
      style: {
        color: Color.Orange,
      },
    },
    {
      types: ['operator'],
      style: {
        color: Color.Blue,
      },
    },
    {
      types: ['tag', 'selector', 'function'],
      style: {
        color: Color.Green,
      },
    },
    {
      types: ['comment', 'punctuation'],
      style: {
        color: Color.GrayLight,
      },
    },
    {
      types: ['tag-id', 'atrule-id', 'placeholder', 'constant'],
      style: {
        color: Color.Pink,
      },
    },
    {
      types: ['italic'],
      style: {
        fontStyle: 'italic',
      },
    },
    {
      types: ['important', 'bold'],
      style: {
        fontWeight: 'bold',
      },
    },
  ],
}

export default theme
